.container .slice {
    transition-duration: 0.3s;
    transition-property: filter, opacity;
    filter: saturate(100%);
    opacity: 1;
    cursor: pointer;
}

.container.hasHighlight .slice {
    filter: saturate(50%);
    opacity: 0.2;
}

.container.hasHighlight .slice:hover {
    filter: saturate(100%);
    opacity: 1;
}

.container.hasHighlight .textOnChartPointBar:hover {
    filter: saturate(100%);
    opacity: 1;
}

