@font-face {
    font-family: 'Avenir Next LT Pro Condensed';
    font-style: normal;
    font-weight: normal;
    src: local('Avenir Next LT Pro Condensed'), url('avenir-next-lt-pro/AvenirNextLTPro-Cn.woff') format('woff');
}

@font-face {
    font-family: 'Avenir Next LT Pro Demi';
    font-style: normal;
    font-weight: normal;
    src: local('Avenir Next LT Pro Demi'), url('avenir-next-lt-pro/AvenirNextLTPro-Demi.woff') format('woff');
}

@font-face {
    font-family: 'Avenir Next LT Pro Demi Condensed';
    font-style: normal;
    font-weight: normal;
    src: local('Avenir Next LT Pro Demi Condensed'), url('avenir-next-lt-pro/AvenirNextLTPro-DemiCn.woff') format('woff');
}

@font-face {
    font-family: 'Avenir Next LT Pro Heavy Condensed';
    font-style: normal;
    font-weight: normal;
    src: local('Avenir Next LT Pro Heavy Condensed'), url('avenir-next-lt-pro/AvenirNextLTPro-HeavyCn.woff') format('woff');
}

@font-face {
    font-family: 'Avenir Next LT Pro Medium Condensed';
    font-style: normal;
    font-weight: normal;
    src: local('Avenir Next LT Pro Medium Condensed'), url('avenir-next-lt-pro/AvenirNextLTPro-MediumCn.woff') format('woff');
}

@font-face {
    font-family: 'Avenir Next LT Pro Regular';
    font-style: normal;
    font-weight: normal;
    src: local('Avenir Next LT Pro Regular'), url('avenir-next-lt-pro/AvenirNextLTPro-Regular.woff') format('woff');
}

@font-face {
    font-family: 'Avenir Next LT Pro Ultra Light Condensed';
    font-style: normal;
    font-weight: normal;
    src: local('Avenir Next LT Pro Ultra Light Condensed'), url('avenir-next-lt-pro/AvenirNextLTPro-UltLtCn.woff') format('woff');
}

@font-face {
    font-family: 'Avenir Next LT Pro Bold';
    font-style: normal;
    font-weight: normal;
    src: local('Avenir Next LT Pro Bold'), url('avenir-next-lt-pro/AvenirNextLTPro-Bold.woff') format('woff');
}

@font-face {
    font-family: 'Avenir Next LT Pro Bold Condensed';
    font-style: normal;
    font-weight: normal;
    src: local('Avenir Next LT Pro Bold Condensed'), url('avenir-next-lt-pro/AvenirNextLTPro-BoldCn.woff') format('woff');
}

@font-face {
    font-family: 'Avenir Next LT Pro Bold Condensed Italic';
    font-style: normal;
    font-weight: normal;
    src: local('Avenir Next LT Pro Bold Condensed Italic'), url('avenir-next-lt-pro/AvenirNextLTPro-BoldCnIt.woff') format('woff');
}

@font-face {
    font-family: 'Avenir Next LT Pro Condensed Italic';
    font-style: normal;
    font-weight: normal;
    src: local('Avenir Next LT Pro Condensed Italic'), url('avenir-next-lt-pro/AvenirNextLTPro-CnIt.woff') format('woff');
}

@font-face {
    font-family: 'Avenir Next LT Pro Demi Condensed Italic';
    font-style: normal;
    font-weight: normal;
    src: local('Avenir Next LT Pro Demi Condensed Italic'), url('avenir-next-lt-pro/AvenirNextLTPro-DemiCnIt.woff') format('woff');
}

@font-face {
    font-family: 'Avenir Next LT Pro Demi Italic';
    font-style: normal;
    font-weight: normal;
    src: local('Avenir Next LT Pro Demi Italic'), url('avenir-next-lt-pro/AvenirNextLTPro-DemiIt.woff') format('woff');
}

@font-face {
    font-family: 'Avenir Next LT Pro Heavy Condensed Italic';
    font-style: normal;
    font-weight: normal;
    src: local('Avenir Next LT Pro Heavy Condensed Italic'), url('avenir-next-lt-pro/AvenirNextLTPro-HeavyCnIt.woff') format('woff');
}

@font-face {
    font-family: 'Avenir Next LT Pro Italic';
    font-style: normal;
    font-weight: normal;
    src: local('Avenir Next LT Pro Italic'), url('avenir-next-lt-pro/AvenirNextLTPro-It.woff') format('woff');
}

@font-face {
    font-family: 'Avenir Next LT Pro Medium Condensed Italic';
    font-style: normal;
    font-weight: normal;
    src: local('Avenir Next LT Pro Medium Condensed Italic'), url('avenir-next-lt-pro/AvenirNextLTPro-MediumCnIt.woff') format('woff');
}

@font-face {
    font-family: 'Avenir Next LT Pro Ultra Light Condensed Italic';
    font-style: normal;
    font-weight: normal;
    src: local('Avenir Next LT Pro Ultra Light Condensed Italic'), url('avenir-next-lt-pro/AvenirNextLTPro-UltLtCnIt.woff') format('woff');
}